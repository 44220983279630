body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotate {
  animation: rotation 8s infinite linear;
}